<template>
    <div v-if="this.params.data.receipt" :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="FileTextIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="callShowPopup" />
    </div>
</template>

<script>
    export default {
        name: 'CellRendererActionsRequestsAdmin',
        data() {
            return {
                popupActive: false,
                isPdf: false
            }
        },
        methods: {
            setFileType() {
                if(this.params.data.receipt) {
                    let {length, [length-1]: ext} = this.params.data.receipt.split('.')
                    this.isPdf = (ext === 'pdf');
                }
            },
            callShowPopup() {
                this.params.showPopup(this.isPdf, this.$baseUrlImg + this.params.data.receipt)
            }
        },
        mounted() {
            this.setFileType()
        }
    }
</script>
<style>
    .vs-popup--content { 
        height:100% !important;
    }
</style>
