<template>
  <div class="vx-row">
    <div v-if="$acl.check('owner')" class="vx-card p-6">
      <h5 style="margin-bottom:9px">Solicitudes de Pago</h5>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  requests.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : requests.length
                }}
                de {{ requests.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
          <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
          <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
          <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Buscar..."/>
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
            >Exportar</vs-button>
        </div>

      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="requests"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </div>

    <div v-if="$acl.check('school')" class="vx-card p-6">
      <div class="w-full">
        <h5 class="my-2">Reporte de Solicitudes de Pago - Servicios</h5>
        <!-- <span class="py-3">Corresponde a los pagos recibidos a través del módulo de Servicios Únicos (módulo deprecado en junio 2024).</span> -->
        <span class="py-3">Corresponde a los pagos recibidos a través del módulo de Servicios Únicos.</span>
      </div>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  requests.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : requests.length
                }}
                de {{ requests.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
          <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
          <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
          <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Buscar..."/>
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
            >Exportar</vs-button>
        </div>

      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefsServices"
        :defaultColDef="defaultColDef"
        :rowData="requests"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </div>

    <div v-if="$acl.check('superadmin')" class="vx-card p-6">
      <h5 style="margin-bottom:9px">Solicitudes de Pago</h5>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  requests.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : requests.length
                }}
                de {{ requests.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
          <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
          <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
          <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Buscar..."/>
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
            >Exportar</vs-button>
        </div>

      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefsSuperAdmin"
        :defaultColDef="defaultColDef"
        :rowData="requests"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </div>
    <vs-popup fullscreen title="Recibo" :active.sync="popupActive">
      <iframe v-if="isPdf" :src="receiptUrl+'#toolbar=0'" frameborder="0" style="width:100%; height:100%;"></iframe>
      <vs-row v-else vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
          <img :src="receiptUrl" />
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueApexCharts from "vue-apexcharts";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import { AgGridVue } from "@ag-grid-community/vue";
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import reportModule from "@/store/report/moduleReport.js";
import DatePicker from "vuejs-datepicker";
// import BtnCellRenderer from "/var/www/tiptapadmin/btn-cell-renderer.js";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererActions from './cell-renderer/CellRendererActionsRequestsAdmin.vue';
import * as lang from 'vuejs-datepicker/src/locale/Language';

export default {
  data() {
    return {
      modules: AllCommunityModules,

      from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      saleTypesStatistics: [],

      //Table
      searchQuery: "",
      gridOptions: {},

      languages: lang,

      maxPageNumbers: 6,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      groupHeaderHeight: 150,
      headerHeight: 100,
      columnDefs: [
        {
          headerName: "Id",
          field: "id_payment_request",
          width: 100,
          filter: false,
        },
        {
          headerName: "Cafeteria",
          field: "name_institution",
          width: 380,
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Total Tiptap",
          field: "tiptap_total",
          filter: true,
          width: 120,
          valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_total, '$ '),
        },
        {
          headerName: "Total Recargas",
          field: "recharges_total",
          filter: true,
          width: 120,
          valueFormatter: params => this.$options.filters.currencySign(params.data.recharges_total, '$ '),
        },
        {
          headerName: "Comisión Total",
          field: "commission_total",
          filter: true,
          width: 120,
          valueFormatter: params => this.$options.filters.currencySign(params.data.commission_total, '$ '),
        },
        {
          headerName: "Total Pago",
          field: "total_pay",
          filter: true,
          width: 120,
          valueFormatter: params => this.$options.filters.currencySign(params.data.total_pay, '$ '),
          cellStyle: params => params.data.status == 0 ? {color: 'blue', fontWeight: '600'} : "",
        },
        {
          headerName: "Estado",
          field: "status_type_description",
          filter: true,
          floatingFilter: true,
          width: 150,
          cellStyle: params => params.data.status == 0 ? {color: 'blue'} : "",
        },
        {
          headerName: "Fecha Solicitud",
          field: "date_request",
          filter: true,
          comparator: this.myDateComparator,
        },
        {
          headerName: "Fecha Pago",
          field: "pay_date",
          filter: true,
          comparator: this.myDateComparator,
        },
        {
          headerName: "Recibo",
          field: "receipt",
          filter: false,
          width: 120,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            showPopup: this.showPopup.bind(this)
          }
        },
/*         {
          headerName: "Acción",
          field: "icons",
          width: 140,
          filter: true,
              //cellRenderer: "btnCellRenderer",
        cellRendererParams: {
          clicked: function(field) {
            alert(`${field} was clicked`);
        }
        }
        }, */
      ],
      columnDefsServices: [
        {
          headerName: "Id",
          field: "id_payment_request",
          width: 100,
          filter: false,
        },
        {
          headerName: "Escuela",
          field: "name_institution",
          width: 380,
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Total Tiptap",
          field: "total",
          filter: true,
          width: 120,
          valueFormatter: params => this.$options.filters.currencySign(params.data.total, '$ '),
        },
        {
          headerName: "Comisión Total",
          field: "tiptap_amount_commission",
          filter: true,
          width: 120,
          valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_amount_commission, '$ '),
        },
        {
          headerName: "Total Pago",
          field: "total_pay",
          filter: true,
          width: 120,
          valueFormatter: params => this.$options.filters.currencySign(params.data.total_pay, '$ '),
          cellStyle: params => params.data.status == 0 ? {color: 'blue', fontWeight: '600'} : "",
        },
        {
          headerName: "Estado",
          field: "status_type_description",
          filter: true,
          floatingFilter: true,
          width: 150,
          cellStyle: params => params.data.status == 0 ? {color: 'blue'} : "",
        },
        {
          headerName: "Fecha Solicitud",
          field: "date_request",
          filter: true,
          comparator: this.myDateComparator,
        },
        {
          headerName: "Fecha Pago",
          field: "pay_date",
          filter: true,
          comparator: this.myDateComparator,
        },
        {
          headerName: "Recibo",
          field: "receipt",
          filter: false,
          width: 120,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            showPopup: this.showPopup.bind(this)
          }
        },
      ],
      columnDefsSuperAdmin: [
        {
          headerName: "ID",
          field: "id",
          width: 100,
          filter: false,
        },
        // {
        //   headerName: "Administrador",
        //   field: "name_admin",
        //   width: 280,
        //   filter: true,
        // },
        {
          headerName: "Tipo",
          field: "type_description",
          width: 200,
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Total",
          field: "total_amount",
          filter: true,
          width: 120,
          valueFormatter: params => this.$options.filters.currencySign(params.data.total_amount, '$ '),
        },
        {
          headerName: "% Comisión",
          field: "commission",
          filter: true,
          width: 160,
          valueFormatter: params => this.$options.filters.currencyPercentage(params.data.commission, ' %'),
        },
        {
          headerName: "Total Pago",
          field: "commission_amount",
          filter: true,
          width: 120,
          valueFormatter: params => this.$options.filters.currencySign(params.data.commission_amount, '$ '),
          cellStyle: params => params.data.status == 1 ? {color: 'blue', fontWeight: '600'} : "",
        },
        // {
        //   headerName: "Estado",
        //   field: "status_type_description",
        //   filter: true,
        //   width: 150,
        //   cellStyle: params => params.data.status == 0 ? {color: 'blue'} : "",
        // },
        {
          headerName: "Fecha Solicitud",
          field: "date_request",
          filter: true,
        },
        {
          headerName: "Fecha Pago",
          field: "pay_date",
          filter: true,
        },
        {
          headerName: "Recibo",
          field: "receipt",
          filter: false,
          width: 120,
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      requests: [],

      // Popup Receipt
      popupActive: false,
      isPdf: false,
      receiptUrl: '',

      // Cell Renderer Components
      components: {
          CellRendererActions,
      },
    };
  },
  components: {
    //BtnCellRenderer,
    DatePicker,
    VuePerfectScrollbar,
    VueApexCharts,
    StatisticsCardLine,
    AgGridVue,
    CellRendererActions
  },

  beforeMount(){
        this.frameworkComponents = {
        //btnCellRenderer: BtnCellRenderer
      };
  },

  created() {
    //Modules
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }
    if(this.$acl.check('owner'))
      this.loadOwnerTable();

    if(this.$acl.check('school'))
      this.loadShoolTable();

    if(this.$acl.check('superadmin'))
      this.loadSuperAdminTable();
  },

  methods: {
    showPopup(isPdf, receipt) {
      this.isPdf = isPdf
      this.receiptUrl = receipt
      this.popupActive = true
    },
    isDate(val) {
      return val instanceof Date && !isNaN(val.valueOf())
    },
    loadOwnerTable() {
      var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};
      this.$store.dispatch("reportModule/getOwnerPayments",{id_owner:this.$store.state.AppActiveUser.account.id, dates:dates})
        .then((response) => {
          let s = response.data.requests;
          if (response.data.requests.length > 0) {
            s.forEach((element) => {
                element.date_request = new Date(element.date_request).toLocaleString("es-AR");
                element.pay_date = element.pay_date ? new Date(element.pay_date).toLocaleString("es-AR", this.$localeDateOptions) : "";
                element.status_type_description = this.$store.state.AppData.ownerPaymentStatus.find(p => p.status_type == element.status).status_type_description;
            });
          }
          this.requests = s;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadShoolTable() {
      var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};
      this.$store.dispatch("reportModule/getSchoolPayments",{id_owner:this.$store.state.AppActiveUser.account.id, dates:dates})
        .then((response) => {
          let s = response.data.requests;
          if (response.data.requests.length > 0) {
            s.forEach((element) => {
                element.date_request = new Date(element.date_request).toLocaleString("es-AR", this.$localeDateOptions);
                element.pay_date = element.pay_date ? new Date(element.pay_date).toLocaleString("es-AR", this.$localeDateOptions) : "";
                element.status_type_description = this.$store.state.AppData.ownerPaymentStatus.find(p => p.status_type == element.status).status_type_description;
                element.name_institution = response.data.institutions.find(i => i.id == element.id_establishment).name
            });
          }
          this.requests = s;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadSuperAdminTable() {
      var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};
      this.$store.dispatch("reportModule/getSuperAdminCanceledPayments",{dates:dates})
        .then((response) => {
          let s = JSON.parse(JSON.stringify(response.data));
          if (response.data.length > 0) {
            s.forEach((element) => {
              element.date_request = element.request_date ? new Date(element.request_date).toLocaleDateString("es-AR", this.$localeDateOptions) : "";
              element.pay_date = element.pay_date ? new Date(element.pay_date).toLocaleDateString("es-AR", this.$localeDateOptions) : "";
              element.status_description = this.$store.state.AppData.saPaymentStatus.find(p => p.status_type == element.status).status_type_description;
              // element.name_admin = "Admin País";
              element.type_description = element.type == 1 ? "Anualidad" : "Ventas Tiptap";
            });
          }
          this.requests = s;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    myDateComparator(date1, date2) {
      var date1Number = this.monthToComparableNumber(date1);
      var date2Number = this.monthToComparableNumber(date2);
      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }
      return date1Number - date2Number;
    },
    monthToComparableNumber(date) {
      if (date === undefined || date === null || date.length !== 10) {
        return null;
      }
      var yearNumber = date.substring(6, 10);
      var monthNumber = date.substring(3, 5);
      var dayNumber = date.substring(0, 2);
      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      return result;
    },
  },

  watch: {
        from: function () {
          if (this.$acl.check('owner')) { this.loadOwnerTable(); }
          if (this.$acl.check('superadmin')) { this.loadSuperAdminTable(); }
          if (this.$acl.check('school')) { this.loadShoolTable(); }
        },
        to: function () {
          if (this.$acl.check('owner')) { this.loadOwnerTable(); }
          if (this.$acl.check('superadmin')) { this.loadSuperAdminTable(); }
          if (this.$acl.check('school')) { this.loadShoolTable(); }
        },
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 6;
        // this.gridOptions.columnApi.setColumnPinned('email', null)
      }
      //   else this.gridOptions.columnApi.setColumnPinned('email', 'left')
    },
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },

  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
};

</script>

<style>
.ag-header-cell-label .ag-header-cell-text{
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}
</style>
